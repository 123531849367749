<template>
  <transition name="mask">
  <div 
    class="mask" 
    v-if="open"
    @keyup.esc="close"
    >
  </div>
  </transition>
  <transition name="modal">
    <div class="wrapper" 
      v-if="open"
        @click="close" 
        @keyup.esc="close"
        tabindex="0"
        ref="wrapper"
      >
      <PageContent>
        <div class="container" @click.stop>
          <div class="header" @click.stop>
            <SubHeading>
              <slot name="header"></slot>
            </SubHeading>
          </div>
          <div class="body" @click.stop>
            <slot name="body"></slot>
          </div>
          <div class="footer" @click.stop>
            <slot name="footer"></slot>
            <button class="close" @click.stop="close">Close</button>
          </div>
        </div>
      </PageContent>
    </div>
  </transition>
  
</template>

<style scoped>
  .mask {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.3);
    width: 100%;
    height: 100%;
    z-index: 999;
  }
  .wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1000;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  .container {
    background-color: white;
    padding: 40px;
    margin-top: auto;
    box-shadow: 0 4px 8px rgba(0,0,0,0.9);
    border-radius: 20px;
  }
  .header {
    margin-bottom: -25px;
    margin-top: -15px;
  }
  .body {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
  }
  .footer {
    display: flex;
    justify-content: center;
  }
  .close {
    margin-top: 10px;
    font-size: 1.3rem;
    background-color: darkred;
    color: white;
    border: 1px solid grey;
    padding: 5px 20px;
    border-radius: 15px;
    cursor: pointer;
  }
  .close:focus {
    outline: none;
  }

  .modal-enter-active { animation: modalBounce 0.1s ease-in forwards; }
  .modal-leave-active { animation: modalBounce 0.1s ease-out reverse; }

  .mask-enter-active { animation: maskShow 0.3s ease-in forwards; }
  .mask-leave-active { animation: maskShow 0.3s ease-out reverse; }

  @keyframes modalBounce {
    0% { opacity: 0; transform: translateY(-150px); }
    70% { opacity: 0.7; transform: translateY(10px); }
    100% { opacity: 1; transform: translateY(0); }
  }
  @keyframes maskShow {
    from { opacity: 0;}
    to { opacity: 1;}
  }

</style>

<script>
import {ref, watch} from 'vue';
import PageContent from './PageContent'
import SubHeading from './SubHeading'
export default {
  name: "Modal",
  components: {
    PageContent,
    SubHeading
  },
  props: {
    open: { type: Boolean, default: false}
  },
  emits: ['close'],
  setup(_, context) {
    const wrapper = ref(null);
    function close() {
      context.emit('close');
    }
    watch(wrapper, () => {
      if (wrapper.value) {
        wrapper.value.focus();
      }
    })
    return { close, wrapper }
  }
}
</script>