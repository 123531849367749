<template>
  <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
  <div class="root">
    <div class="article" v-for="article in articles" :key="article" @click="$emit('clicked',article)">
      <i class="material-icons">article</i>
      <div class="name">{{article}}</div>
      <div class="link">{{`\\articles\\${article}.html`}}</div>
      <button v-if="showDelete" @click.stop="$emit('del', article)">Delete</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    articles: {
      type: Array,
      required: true
    },
    showDelete: Boolean
  },
  emits: ['clicked','del']
}
</script>

<style scoped>
  .article {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    margin: 3px 0;
  }
  .article:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }
  .name {
    width: 200px;
    text-decoration: underline;
  }
  .link {
    flex: 1;
    text-decoration: underline;
  }
  button {
    text-decoration: none;
  }
</style>