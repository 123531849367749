<template>
  <PageContent>
    <SubHeading>Edit Cards</SubHeading>
    <AdminBreadCrumb />
    <div v-if="cardIndex === -1" class="actions">
      <div>Select a Card to Edit or <button @click="AddNewCard">Add New</button></div>
      <div>Fitler Card by Title: <input type="text" v-model="cardFilter" class="filterInput"></div>
    </div>
    <hr>
    <transition name="fade">
    <div class="editForm" v-if="cardIndex != -1">
      <div class="editRow">
        <div>Image URL</div>
        <input type="text" v-model="editCard.imageSrc">
        <button @click="viewImages = true" class="elipsis">...</button>
      </div>
      <div class="editRow">
        <div>Title</div>
        <input type="text" v-model="editCard.title">
      </div>
      <div class="editRow">
        <div>Date</div>
        <input type="Date" v-model="editCard.date">
      </div>
      <div class="editRow">
        <div>Summary</div>
        <textarea v-model="editCard.summary" rows="5"/>
      </div>
      <div class="editRow">
        <div>Link</div>
        <input type="text" v-model="editCard.link">
        <button @click="viewArticles = true" class="elipsis">...</button>
      </div>
      <div class="editRow">
        <div>Link Text</div>
        <input type="text" v-model="editCard.linkTxt">
      </div>
      <div class="editRow buttons">
        <button @click="Delete" class="cancel">Delete</button>
        <button @click="SaveCard">Save</button>
      </div>
    </div>
    </transition>
    <transition name="fade">
      <Cards v-if="loaded && cardIndex === -1" :externalSource="filteredCards" @card-clicked="SelectCard" :editMode="true"/>
    </transition>
    <Modal :open="viewImages" @close="viewImages = !viewImages">
      <template #header>Image Management</template>
      <template #body>
        <div class="fileUpload">
          <span>Would you like to upload a new image: </span>
          <FileUpload type="image" @file-uploaded="ImageLoaded" />
        </div>
        <ImageList :images="images" v-if="images.length" @image-clicked="chooseImage"></ImageList>
      </template>
    </Modal>
    <Modal :open="viewArticles" @close="viewArticles = !viewArticles">
      <template #header>Article List</template>
      <template #body>
        <ArticleList :articles="articles" @clicked="articleClicked"/>
      </template>
    </Modal>
  </PageContent>
</template>

<style scoped>
  .actions{
    display: flex;
    justify-content: space-between;
  }
  .filterInput{
    font-size: 1.3rem;
  }
  .editForm{
    display: flex;
    flex-direction: column;
  }
  .editRow{
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }
  .editRow > div {
    width: 150px;
  }
  .editRow > input, .editRow > textarea {
    flex: 1
  }
  .editRow.buttons {
    justify-content: space-between;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s ease-in-out;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  button {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.3rem;
    border-radius: 10px;
  }
  button.cancel {
    background-color: #f44336;;
  }
  .elipsis {
    font-size: 0.8rem;
    padding: 5px;
    border-radius: 0;
  }
</style>

<script>
import Cards from '@/components/Cards'
import PageContent from '@/components/PageContent'
import SubHeading from '@/components/SubHeading'
import ImageList from '@/components/ImageList'
import ArticleList from '@/components/ArticleList'
import Modal from '@/components/Modal'
import FileUpload from '@/components/FileUpload'
import AdminBreadCrumb from '@/components/Admin/AdminBreadCrumb'

import ImageService from '@/services/ImageService'
import CardService from '@/services/CardService'
import ArticleService from '@/services/ArticleService'

export default {
  name: "EditCards",
  components: {
    Cards,
    PageContent,
    SubHeading,
    ImageList,
    Modal,
    FileUpload,
    AdminBreadCrumb,
    ArticleList
  },
  data() {
    return {
      Cards: [],
      loaded: false,
      editCard: {
        imageSrc: "src",
        title: "",
        date: new Date(),
        summary: "",
        link: "",
        linkTxt: ""
      },
      cardIndex: -1,
      cardFilter: "",
      viewImages: false,
      viewArticles: false,
      images: [],
      articles: []
    }
  },
  async mounted() {
    this.Cards = await CardService.getCards();
    this.images = await ImageService.LoadImages();
    this.articles = await ArticleService.getArticles();
    this.loaded = true;
  },
  computed: {
    filteredCards() {
      if (!this.cardFilter) {
        return this.Cards;
      }
      return this.Cards.filter((card) => {
        return card.title.toLowerCase().includes(this.cardFilter.toLowerCase());
      });

    }
  },
  methods: {
    SelectCard(index) {
      this.editCard = this.Cards[index];
      this.editCard.date = new Date(this.Cards[index].date).toISOString().slice(0,10);
      this.cardIndex = index;
    },
    AddNewCard(){
      this.editCard = {
        imageSrc: "",
        title: "",
        date: new Date().toISOString().slice(0,10),
        summary: "",
        link: "",
        linkTxt: ""
      }
      this.Cards.push(this.editCard);
      this.cardIndex = this.Cards.length - 1;
    },
    Delete() {
      this.Cards.splice(this.cardIndex,1);
      this.Persist();
    },
    SaveCard(){
      if (this.cardIndex === -1) {
        this.Cards.push(this.editCard);
      }
      this.Persist();
    },
    async Persist() {
      await CardService.saveCards(this.Cards);
      this.cardIndex = -1;
    },
    chooseImage(img) {
      this.editCard.imageSrc = `/images/${img.name}`;
      this.viewImages = false;
    },
    async ImageLoaded() {
      this.images = await ImageService.LoadImages();
      alert("Image Uploaded");
    },
    articleClicked(name) {
      this.editCard.link = `/article/${name}`;
      this.editCard.linkTxt = name;
      this.viewArticles = false;
    }
  }
}
</script>