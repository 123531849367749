import axios from "axios";
import Config from '@/config/index.js'

import store from '@/store'

const getArticles = async () => {
  let token = store.getters.token;
  const articles = (await axios.get(`${Config.baseAPIPath}/ListArticles`,
    { headers: { "Access-Control-Allow-Origin": "*", "Authorization": `Bearer ${token}` } }
  )).data;

  return articles;
}

const saveArticle = async (name, content) => {
  let token = store.getters.token;

  await axios.post(`${Config.baseAPIPath}/SaveArticle`,
      { name, body: content },
      { headers: { "Access-Control-Allow-Origin": "*", "Authorization": `Bearer ${token}` } }
    );
}

const getArticle = async (name) => {
  const html = (await axios.get(`/articles/${name}.html`)).data;
  return html;
}
const deleteArticle = async (name) => {
  let token = store.getters.token;

  await axios.post(`${Config.baseAPIPath}/DeleteArticle`,
    { name },
    { headers: { "Access-Control-Allow-Origin": "*", "Authorization": `Bearer ${token}` } }
  );
}

export default {
  getArticles,
  saveArticle,
  getArticle,
  deleteArticle
}