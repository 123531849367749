import axios from "axios";
import Config from '@/config/index.js'
import store from '@/store'
const getCards = async () => (await axios.get('/data/cards.json')).data;
const saveCards = async (cards) => {
  const token = store.getters.token;

  await axios
    .post(
      `${Config.baseAPIPath}/SaveCards`,
      { body: JSON.stringify(cards) },
      { headers: { "Access-Control-Allow-Origin": "*", "Authorization": `Bearer ${token}` } }
    );
}

export default {
  getCards,
  saveCards
}